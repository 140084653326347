
import { api } from "@/api/api";
import { ApiHierarchicalAreaDtoType, ApiUpsertCurriculumDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { CurriculumRouteNames } from "@/shared/enums/RouteNames/curriculumRouteNamesEnum";
import { formatRelative } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";
import { Store } from "vuex";

interface Draft extends ApiUpsertCurriculumDto {
  subCourseName: string;
  mainCourseName: string;
  mainCourseId?: number;
}

const initialData = () => ({
  search: "",
  previousMain: "",
  testValues: [],
});

export default defineComponent({
  name: "StudieplanDraftsPage",
  components: {
    BaseTableFiltered,
    BaseLayout,
  },
  setup() {
    const store = useStore<StoreState>();
    const router = useRouter();

    const drafts = ref<Draft[]>([]);

    onMounted(async () => {
      loadDrafts(store, drafts);
    });

    const deleteDraft = async (element: Draft) => {
      globalLoadingWrapper({ blocking: true, type: LoadingType.Spinner }, async () => {
        await api.curriculum.deleteDraftAsync(element.draftId);
        openNotification(store, NotificationItemType.Success, "Utkast slettet");
      });
      loadDrafts(store, drafts);
    };

    const openEdit = (element: Draft) => {
      router.push({
        path: `/studieplaner/utkast/${element.draftId}`,
        query: { mainCourseId: element.mainCourseId?.toString() },
      });
    };

    const openCurriculumDetails = (element: Draft) => {
      router.push(`/studieplaner/details/${element.draftId}`);
    };

    return {
      headers,
      deleteDraft,
      drafts,
      openEdit,
      openCurriculumDetails,
      formatRelative,
      getName: computed(() => (name: string) => name ? name : "Studieplanen har ingen tittel."),
      CurriculumRouteNames,
    };
  },
  data() {
    return initialData();
  },
});

const loadDrafts = async (store: Store<StoreState>, drafts: Ref<Draft[]>) => {
  let draftsResponse: ApiUpsertCurriculumDto[] = [];
  let courseAreas: ApiHierarchicalAreaDtoType[] = [];

  globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
    await Promise.allSettled([
      api.curriculum.getDraftsPerUserAsync().then((response) => {
        draftsResponse = response.data;
      }),
      api.coursearea.getCourseAreasAsync().then((response) => {
        courseAreas = response.data;
      }),
    ]);

    drafts.value = mapDraftResponse(draftsResponse, courseAreas);
  });
};

const mapDraftResponse = (draftResponse: ApiUpsertCurriculumDto[], courseAreas: ApiHierarchicalAreaDtoType[]) => {
  // todo should use default options instead for area? TODO
  const { subCourseMap, parentCourseMap } = generateMainAndSubCourseMap(courseAreas);

  return draftResponse.map((current) => {
    const subCourse = subCourseMap.get(current.subCourseId);
    if (!subCourse?.parentId) {
      throw new Error("subCourseId parentId not found");
    }
    const mainCourse = parentCourseMap.get(subCourse?.parentId);
    return {
      ...current,
      subCourseName: subCourse?.description ?? "",
      mainCourseName: mainCourse?.description ?? "",
      mainCourseId: mainCourse?.id,
    };
  });
};

// assuming just two levels
const generateMainAndSubCourseMap = (courseAreas: ApiHierarchicalAreaDtoType[]) => {
  const subCourseMap = new Map<number, ApiHierarchicalAreaDtoType>();
  const parentCourseMap = new Map<number, ApiHierarchicalAreaDtoType>();
  courseAreas.forEach((currentParent) => {
    if (currentParent.children) {
      currentParent.children.forEach((currentChild) => {
        subCourseMap.set(currentChild.id, { ...currentChild });
        // some children have children, but ignored for now
      });
    }
    parentCourseMap.set(currentParent.id, { ...currentParent });
  });
  return { subCourseMap, parentCourseMap };
};

const headers = [
  { text: "Navn", value: "name" },
  { text: "Handlinger", value: "actions", sortable: false },
  {
    text: "Hovedområde",
    value: "mainCourseName",
    filter: true,
  },
  {
    text: "Kursområde",
    value: "subCourseName",
    filter: true,
  },
  {
    text: "Oppdatert",
    value: "updated",
  },
  {
    text: "Undervisningsform",
    value: "teachingMethod",
    filter: true,
  },
];
